import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import INFO from "../images/SFPlatform_InfoGraph.png"


const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 60px auto 30px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    @media only screen and (max-width: 1024px) {
        margin-top: 40px;
        margin-bottom: 0;
    }

    .info {
        width: 75%;
        margin: 60px auto;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 90px;
    }
`;

const DetailFlex = styled.div`
    display: flex;
    justify-content: center;
    background: #E5F5FB;
    margin-bottom: 100px;
    @media only screen and (max-width: 1140px) {
        padding: 0 20px;
        margin-bottom: 60px;
    }

    #rootStock {
        width: 250px;
        margin-right: 50px;
    }
`;

const DetailText = styled.div`
    padding: 47px 0 100px;
    max-width: 1110px;
    display: flex;
    flex-wrap: wrap;

    p {
        width: 100%;
    }

    @media only screen and (max-width: 1140px) {
        padding: 0 0 100px;

        p {
            width: 100%;
            margin-top: 35px;
        }
    }
`;

const StyledLink = styled(Link)`
`;



const Details = () => (
    <>
        <DetailFlex>
            <DetailText>
                <p className="intro">Ladd Partners extends the CRM and ERP applications built on the Salesforce platform. We can help you find ways to enrich user experiences and streamline processes. Your imagination is the only thing that limits salesforce process.</p>
            </DetailText>
        </DetailFlex>
        <WrapperDiv>
        <Flex>
            <h4 className="margin-bottom-0">What is Salesforce? </h4>
            <p className="margin-bottom-60">Salesforce is a company that makes cloud-based software designed to help businesses find more prospects, close more deals, and wow customers with amazing service. Customer 360, our complete suite of products, unites your sales, service, marketing, commerce, and IT teams with a single, shared view of customer information, helping you grow relationships with customers and employees alike.</p>

            <h4 className="margin-bottom-0">What does Salesforce do?</h4>
            <p className="margin-bottom-60">It helps teams work better together. Your business may use a single Customer 360 app, or a combination of many. By improving team communications and productivity, businesses drive greater success.</p>

            <h4 className="margin-bottom-0">Salesforce as a platform</h4>
            <p className="margin-bottom-20">Applications built on Salesforce provide a single technical stack to administrate your business, reducing your total cost of ownership. Including security, reduced data integrations, robust reporting, and analytic tools with a single login. </p>

            <p className="margin-bottom-20">We offer implementation, customization, and support services for various Salesforce applications, emphasizing integration between these applications and Operations and Back Office from <StyledLink to="/services/certinia">Certinia </StyledLink> and <StyledLink to="/services/rootstock">Rootstock </StyledLink> ERPs to complete your 360-degree view of your business. </p>

            <p className="margin-bottom-60">We’re a Salesforce Registered Consulting Partner, but our expertise, experience, and execution drive our customers' success. </p>

            <h4 className="margin-bottom-0">Democratization of data </h4>
            <p className="margin-bottom-60">Advanced analytics sends an automated notice when a significant or atypical trend or a new service issue is detected. Easy-to-use dashboards make it simple to dig into the data to learn more and instantly take action.</p>

            <img className="info" src={INFO} alt="Infograph"/>

``        </Flex>
        </WrapperDiv>
    </>
)

export default Details

