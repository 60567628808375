import React from "react"
import Hero from "../components/hero-sp"
import SPDetails from "../components/sp-details"

import "../assets/index.css"
import Layout from "../layouts/layout"

export default () => (
    <Layout title="Salesforce Platform | Ladd Partners">
        <Hero/>
        <SPDetails/>

        {/* <Location>
            {({ location }) => {
                return  <><Content location={{ location }} /></>
            }}
        </Location> */}
    </Layout>
)
